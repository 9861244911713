import type { CookieValueTypes } from 'cookies-next';

const hasProcess = typeof process !== 'undefined';
export const isLocal = () => hasProcess && process.env?.NEXT_PUBLIC_MODE === 'local';
export const isServerSide = () => typeof window === 'undefined';
export const getHostname = () => {
  if (isLocal()) {
    return 'localhost';
  }

  try {
    const url = new URL(getBaseURL());

    return url.hostname;
  } catch {
    return '';
  }
};
/**
 *
 * @param defaultBaseUrl fetch API인 경우 axios와는 달리 로컬에서 baseURL이 없는 상태이기 때문에 defaultBaseUrl을 넣어줍니다.
 * @description defaultBaseUrl axios API의 defaultBaseUrl은 알아서 잘 들어갑니다.
 */
export const getBaseURL = (defaultBaseUrl = '') => process.env?.NEXT_PUBLIC_KMONG_API_ENDPOINT ?? defaultBaseUrl;

export const getRewriteURL = (url: string) => {
  const baseURL = getBaseURL();

  if (url?.startsWith('/api/analytics-app')) {
    return {
      baseURL: process.env.NEXT_PUBLIC_MSA_KMONG_ANALYTICS_API_ENDPOINT ?? '',
      url: url.replace('/api/analytics-app', ''),
    };
  }
  if (url?.startsWith('/api/msa')) {
    return {
      baseURL: process.env.NEXT_PUBLIC_MSA_KMONG_API_ENDPOINT ?? '',
      url: url.replace('/api/msa', ''),
    };
  }

  return { baseURL, url };
};

export const addPrefixWithToken = (value: CookieValueTypes) => {
  let token = value;

  if (typeof token !== 'string') {
    token = '';
  }

  return `Bearer ${token}`;
};
